import React, { Component } from 'react';
import '../styles/main.css';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { FaSearch } from 'react-icons/fa';
import { Helmet } from 'react-helmet';
import ImageModal2 from '../imageModal2';

const itemData = 
[
  {
    "img": "https://images.eap.bl.uk/EAP838/EAP838_1_7_5/169.jp2/full/!1400,700/0/default.jpg",
    "link": "https://eap.bl.uk/item/EAP838-1-7-5-169",
    "title": "A man in Ganesh Photo Lab. A studio that was opend by Ganesh Man Chitrakar in early 1970s",
    "date": "1970s"
  },
  {
    "img": "https://images.eap.bl.uk/EAP838/EAP838_1_7_5/219.jp2/full/!1400,700/0/default.jpg",
    "link": "https://eap.bl.uk/item/EAP838-1-7-5-219",
    "title": "A man in Ganesh Photo Lab. A studio that was opend by Ganesh Man Chitrakar in early 1970s",
    "date": "1970s"
  },
  {
    "img": "https://images.eap.bl.uk/EAP838/EAP838_1_7_5/213.jp2/full/!1400,700/0/default.jpg",
    "link": "https://eap.bl.uk/item/EAP838-1-7-5-213",
    "title": "A woman in Ganesh Photo Lab. A studio that was opend by Ganesh Man Chitrakar in early 1970s",
    "date": "1970s"
  },
  {
    "img": "https://images.eap.bl.uk/EAP838/EAP838_1_7_5/199.jp2/full/!1400,700/0/default.jpg",
    "link": "https://eap.bl.uk/item/EAP838-1-7-5-199",
    "title": "A man in Ganesh Photo Lab. A studio that was opend by Ganesh Man Chitrakar in early 1970s",
    "date": "1970s"
  },
  {
    "img": "https://images.eap.bl.uk/EAP838/EAP838_1_7_5/158.jp2/full/!1400,700/0/default.jpg",
    "link": "https://eap.bl.uk/item/EAP838-1-7-5-158",
    "title": "A woman in Ganesh Photo Lab. A studio that was opend by Ganesh Man Chitrakar in early 1970s",
    "date": "1970s"
  },
  {
    "img": "https://images.eap.bl.uk/EAP838/EAP838_1_7_5/149.jp2/full/!1400,700/0/default.jpg",
    "link": "https://eap.bl.uk/item/EAP838-1-7-5-149",
    "title": "A woman in Ganesh Photo Lab. A studio that was opend by Ganesh Man Chitrakar in early 1970s",
    "date": "1970s"
  },
  {
    "img": "https://images.eap.bl.uk/EAP838/EAP838_1_7_5/360.jp2/full/!1400,700/0/default.jpg",
    "link": "https://eap.bl.uk/item/EAP838-1-7-5-360",
    "title": "A man and a woman in Ganesh Photo Lab. A studio that was opend by Ganesh Man Chitrakar in early 1970s",
    "date": "1970s"
  },
  {
    "img": "https://images.eap.bl.uk/EAP838/EAP838_1_7_5/445.jp2/full/!1400,700/0/default.jpg",
    "link": "https://eap.bl.uk/item/EAP838-1-7-5-445",
    "title": "A Newari woman in Ganesh Photo Lab. A studio that was opend by Ganesh Man Chitrakar in early 1970s",
    "date": "1970s"
  },
  {
    "img": "https://images.eap.bl.uk/EAP838/EAP838_1_7_5/217.jp2/full/!1400,700/0/default.jpg",
    "link": "https://eap.bl.uk/item/EAP838-1-7-5-217",
    "title": "Children posing for the camera in Ganesh Photo Lab. A studio that was opend by Ganesh Man Chitrakar in early 1970s",
    "date": "1970s"
  },
  {
    "img": "https://images.eap.bl.uk/EAP838/EAP838_1_7_5/194.jp2/full/!1400,700/0/default.jpg",
    "link": "https://eap.bl.uk/item/EAP838-1-7-5-194",
    "title": "Men pose for the camera in Ganesh Photo Lab. A studio that was opend by Ganesh Man Chitrakar in early 1970s",
    "date": "1970s"
  },
  {
    "img": "https://images.eap.bl.uk/EAP838/EAP838_1_7_5/143.jp2/full/!1400,700/0/default.jpg",
    "link": "https://eap.bl.uk/item/EAP838-1-7-5-143",
    "title": "Woman poses for the camera in Ganesh Photo Lab. A studio that was opend by Ganesh Man Chitrakar in early 1970s",
    "date": "1970s"
  },
  {
    "img": "https://images.eap.bl.uk/EAP838/EAP838_1_7_5/107.jp2/full/!1400,700/0/default.jpg",
    "link": "https://eap.bl.uk/item/EAP838-1-7-5-107",
    "title": "A man poses for the camera in Ganesh Photo Lab. A studio that was opend by Ganesh Man Chitrakar in early 1970s",
    "date": "1970s"
  },
  {
    "img": "https://images.eap.bl.uk/EAP838/EAP838_1_7_5/63.jp2/full/!1400,700/0/default.jpg",
    "link": "https://eap.bl.uk/item/EAP838-1-7-5-63",
    "title": "Children posing for the camera in Ganesh Photo Lab. A studio that was opend by Ganesh Man Chitrakar in early 1970s",
    "date": "1970s"
  },
  {
    "img": "https://images.eap.bl.uk/EAP838/EAP838_1_7_5/173.jp2/full/!1400,700/0/default.jpg",
    "link": "https://eap.bl.uk/item/EAP838-1-7-5-173",
    "title": "Men pose for the camera in Ganesh Photo Lab. A studio that was opend by Ganesh Man Chitrakar in early 1970s",
    "date": "1970s"
  },
  {
    "img": "https://images.eap.bl.uk/EAP838/EAP838_1_7_5/141.jp2/full/!1400,700/0/default.jpg",
    "link": "https://eap.bl.uk/item/EAP838-1-7-5-141",
    "title": "Two women pose for the camera in Ganesh Photo Lab. A studio that was opend by Ganesh Man Chitrakar in early 1970s",
    "date": "1970s"
  },
  {
    "img": "https://images.eap.bl.uk/EAP838/EAP838_1_7_5/140.jp2/full/!1400,700/0/default.jpg",
    "link": "https://eap.bl.uk/item/EAP838-1-7-5-140",
    "title": "A man and a woman in Ganesh Photo Lab. A studio that was opend by Ganesh Man Chitrakar in early 1970s",
    "date": "1970s"
  },
  {
    "img": "https://images.eap.bl.uk/EAP838/EAP838_1_7_5/114.jp2/full/!1400,700/0/default.jpg",
    "link": "https://eap.bl.uk/item/EAP838-1-7-5-114",
    "title": "A young woman poses for the camera in Ganesh Photo Lab. A studio that was opend by Ganesh Man Chitrakar in early 1970s",
    "date": "1970s"
  },
  {
    "img": "https://images.eap.bl.uk/EAP838/EAP838_1_7_5/208.jp2/full/!1400,700/0/default.jpg",
    "link": "https://eap.bl.uk/item/EAP838-1-7-5-208",
    "title": "A man and wife pose with sunglasses and cigarettes in Ganesh Photo Lab. A studio that was opend by Ganesh Man Chitrakar in early 1970s",
    "date": "1970s"
  },
  {
    "img": "https://images.eap.bl.uk/EAP838/EAP838_1_7_5/128.jp2/full/!1400,700/0/default.jpg",
    "link": "https://eap.bl.uk/item/EAP838-1-7-5-128",
    "title": "Two young men pose for the camera in Ganesh Photo Lab. A studio that was opend by Ganesh Man Chitrakar in early 1970s",
    "date": "1970s"
  },
  {
    "img": "https://images.eap.bl.uk/EAP838/EAP838_1_7_5/189.jp2/full/!1400,700/0/default.jpg",
    "link": "https://eap.bl.uk/item/EAP838-1-7-5-189",
    "title": "A man poses against a painted backdrop in Ganesh Photo Lab. A studio that was opend by Ganesh Man Chitrakar in early 1970s",
    "date": "1970s"
  },
  {
    "img": "https://images.eap.bl.uk/EAP838/EAP838_1_7_5/145.jp2/full/!1400,700/0/default.jpg",
    "link": "https://eap.bl.uk/item/EAP838-1-7-5-145",
    "title": "Men pose against a painted backdrop in Ganesh Photo Lab. A studio that was opend by Ganesh Man Chitrakar in early 1970s",
    "date": "1970s"
  },
  {
    "img": "https://images.eap.bl.uk/EAP838/EAP838_1_7_5/388.jp2/full/!1400,700/0/default.jpg",
    "link": "https://eap.bl.uk/item/EAP838-1-7-5-388",
    "title": "A Newari woman dressed in traditional jewellery and clothes in Ganesh Photo Lab. A studio that was opend by Ganesh Man Chitrakar in early 1970s",
    "date": "1970s"
  },
  {
    "img": "https://images.eap.bl.uk/EAP838/EAP838_1_7_5/200.jp2/full/!1400,700/0/default.jpg",
    "link": "https://eap.bl.uk/item/EAP838-1-7-5-200",
    "title": "A soldier in his uniform pose against a painted backdrop in Ganesh Photo Lab. A studio that was opend by Ganesh Man Chitrakar in early 1970s",
    "date": "1970s"
  },
  {
    "img": "https://images.eap.bl.uk/EAP838/EAP838_1_7_5/113.jp2/full/!1400,700/0/default.jpg",
    "link": "https://eap.bl.uk/item/EAP838-1-7-5-113",
    "title": "Two women pose for the camera against a painted backdrop in Ganesh Photo Lab. A studio that was opend by Ganesh Man Chitrakar in early 1970s",
    "date": "1970s"
  }
 ] 

class GaneshPhotoLab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            windowWidth: window.innerWidth,
            searchTerms: '',
            isLoading: false,
            error: null
        };
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    onKeyUp(e) {
        if (e.charCode === 13) {
            window.location.href = '/search?q=' + e.target.value;
        }
    }

    handleResize = (e) => {
        this.setState({ windowWidth: window.innerWidth });
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
    }

    componentWillUnmount() {
        window.addEventListener("resize", this.handleResize);
    }

    render() {
        const { windowWidth, searchTerms, isLoading, error } = this.state;

        return (
            <div className="application">
                <Helmet>
                    <meta charset="utf-8" />
                    <title>Ganesh Photo Lab: Portraits from the Past | Archive Nepal</title>
                    <link rel="canonical" href="https://www.archivenepal.org/aerialviews" />
                    <meta name="description" content="Ganesh Photo Lab: Portraits from the Past" />
                </Helmet>

                <div id="page-wrapper">

                    {windowWidth >= 1265 ? <div className="input-group col-md-4 childSearchBox" style={{ float: 'right', marginTop: '65px' }}>
                        <input type="text" className="form-control input-lg" name="searchTerms" placeholder="Discover Nepal through its history" value={searchTerms} onChange={this.onChange} onKeyPress={this.onKeyUp} style={{ padding: "5px" }} />
                        <Link to={'/search?q=' + this.state.searchTerms} className="searchButton" ><FaSearch /></Link>
                    </div> :
                        <div className="input-group col-md-4 childSearchBox" style={{ float: 'right', marginTop: '65px' }}>
                            <input type="text" className="form-control input-md" name="searchTerms" placeholder="Discover Nepal through its history" value={searchTerms} onChange={this.onChange} onKeyPress={this.onKeyUp} />
                            <Link to={'/search?q=' + this.state.searchTerms} className="searchButton" ><FaSearch /></Link>
                        </div>
                    }
                    <section id="main" className="container">
                        <h2>Ganesh Photo Lab: Portraits from the Past</h2>
                        <h5>Compiled by: Shreeti Sigdel</h5>
                        <section className="box special features">
                            <div className="features-row">
                                <div style={{ textAlign: 'left' }}>
                                    <p> Ganesh Photo Lab, founded by renowned Nepali photographer Ganesh Man Chitrakar, was a pioneering photography studio known for its extensive collection of portraits. The studio captured individuals, families, cultures, and fashion, offering a unique glimpse into life in the 1970 while showcasing the vibrant spirit of the era.</p>
                                </div>
                                <div id="masonry">

                                    {windowWidth >= 1265 ? <Box>
                                        <ImageList variant="masonry" cols={3} gap={20}>
                                            {itemData.map((item) => (
                                                    <ImageListItem key={item.img}>
                                                     <ImageModal2 url={item.img} title={item.title} desc={item.description} salestype='none' link={item.link} />
                                                    </ImageListItem>
                                            ))}
                                        </ImageList>
                                    </Box> :
                                        <Box>
                                            <ImageList variant="masonry" cols={1} gap={1}>
                                                {itemData.map((item) => (
                                                    <ImageListItem key={item.img}>
                                                        <ImageModal2 url={item.img} title={item.title} desc={item.description} salestype='none' link={item.link} />                                    
                                                    </ImageListItem>
                                                ))}
                                            </ImageList>
                                        </Box>
                                    }

                                </div>
                            </div>
                        </section>
                    </section>
                </div>
            </div>
        )
    }
}
export default GaneshPhotoLab;
