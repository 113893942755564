// index.component.js
import React, { Component } from 'react';
import './styles/main.css';
import { Link } from 'react-router-dom';
import { FaSearch } from 'react-icons/fa';
import { FiVideo } from 'react-icons/fi';
import { IoMdBook } from 'react-icons/io';
import { HiOutlinePhotograph } from 'react-icons/hi';
import { BsJournals } from 'react-icons/bs';
import { BiBox } from 'react-icons/bi';
import { IoDocumentTextOutline } from 'react-icons/io5';
import Carousel from 'react-grid-carousel';

export default class Index extends Component {
	constructor(props) {
		super(props);
		this.state = {
			windowWidth: window.innerWidth,
			searchTerms: '',
			isLoading: false,
			error: null
		};
	}

	onChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	}

	onKeyUp(e) {
		if (e.charCode === 13) {
			window.location.href = '/search?q=' + e.target.value;
		}
	}

	handleResize = (e) => {
		this.setState({ windowWidth: window.innerWidth });
	}

	componentDidMount() {
		window.addEventListener("resize", this.handleResize);
	}

	componentWillUnmount() {
		window.addEventListener("resize", this.handleResize);
	}

	render() {
		const { windowWidth, searchTerms, isLoading, error } = this.state;

		if (error) {
			return <p>{error.message}</p>;
		}

		if (isLoading) {
			return <p>Loading ....</p>;
		}

		return (

			<div id="page-wrapper">
				<section id="banner">
					{/*<h3></h3>
					*/}

					<div className="input-group col-md-4 searchBox">
						<input type="text" className="form-control input-lg" name="searchTerms" placeholder="Discover Nepal through its history" value={searchTerms} onChange={this.onChange} onKeyPress={this.onKeyUp} style={{ padding: "5px" }} />
						<Link to={'/search?q=' + this.state.searchTerms} className="searchButton" ><FaSearch /></Link>
					</div>
				</section>
				<section id="main" className="container">
					<h3>Browse by item types:</h3>
					<section className="box special features">
						<h4 style={{textDecoration: 'none'}}>Discover <span style={{color:'#c8102e', fontWeight:'Bold'}}>37,046</span> digital materials</h4> 
						<div className="features-row">
							<Carousel cols={4} rows={1} gap={20} showDots>
								<Carousel.Item>
									<section>
										<span className="icon major"><Link to={'/search?size=n_20_n&filters%5B0%5D%5Bfield%5D=type&filters%5B0%5D%5Bvalues%5D%5B0%5D=image&filters%5B0%5D%5Btype%5D=all'} style={{ textDecoration: 'none' }}><HiOutlinePhotograph size={50} /></Link></span>
										<Link to={'/search?size=n_20_n&filters%5B0%5D%5Bfield%5D=type&filters%5B0%5D%5Bvalues%5D%5B0%5D=image&filters%5B0%5D%5Btype%5D=all'} style={{ textDecoration: 'none' }}><h4 style={{ marginLeft: '15px' }}>32,646 Images</h4></Link>
									</section>
								</Carousel.Item>
								<Carousel.Item>

									<section>
										<span className="icon major"><Link to={'/search?size=n_20_n&filters%5B0%5D%5Bfield%5D=type&filters%5B0%5D%5Bvalues%5D%5B0%5D=video&filters%5B0%5D%5Btype%5D=all'} style={{ textDecoration: 'none' }}><FiVideo size={50} /></Link></span>
										<Link to={'/search?size=n_20_n&filters%5B0%5D%5Bfield%5D=type&filters%5B0%5D%5Bvalues%5D%5B0%5D=video&filters%5B0%5D%5Btype%5D=all'} style={{ textDecoration: 'none' }}><h4 style={{ marginLeft: '15px' }}>422 Videos</h4></Link>
									</section>
								</Carousel.Item>
								<Carousel.Item>

									<section>
										<span className="icon major"><Link to={'search?size=n_20_n&filters%5B0%5D%5Bfield%5D=type&filters%5B0%5D%5Bvalues%5D%5B0%5D=journal&filters%5B0%5D%5Btype%5D=all'} style={{ textDecoration: 'none' }}><BsJournals size={50} /></Link></span>
										<Link to={'search?size=n_20_n&filters%5B0%5D%5Bfield%5D=type&filters%5B0%5D%5Bvalues%5D%5B0%5D=journal&filters%5B0%5D%5Btype%5D=all'} style={{ textDecoration: 'none' }}><h4 style={{ marginLeft: '15px' }}>2,084 Journals</h4></Link>
									</section>
								</Carousel.Item>
								<Carousel.Item>

									<section>
										<span className="icon major"><Link to={'/search?size=n_20_n&filters%5B0%5D%5Bfield%5D=type&filters%5B0%5D%5Bvalues%5D%5B0%5D=book&filters%5B0%5D%5Btype%5D=all'} style={{ textDecoration: 'none' }}><IoMdBook size={50} /></Link></span>
										<Link to={'/search?size=n_20_n&filters%5B0%5D%5Bfield%5D=type&filters%5B0%5D%5Bvalues%5D%5B0%5D=book&filters%5B0%5D%5Btype%5D=all'} style={{ textDecoration: 'none' }}><h4 style={{ marginLeft: '15px' }}> 69 Books</h4></Link>
									</section>
								</Carousel.Item>
								<Carousel.Item>

									<section>
										<span className="icon major"><Link to={'/search?size=n_20_n&filters%5B0%5D%5Bfield%5D=type&filters%5B0%5D%5Bvalues%5D%5B0%5D=object&filters%5B0%5D%5Btype%5D=all'} style={{ textDecoration: 'none' }}><BiBox size={50} /></Link></span>
										<Link to={'/search?size=n_20_n&filters%5B0%5D%5Bfield%5D=type&filters%5B0%5D%5Bvalues%5D%5B0%5D=object&filters%5B0%5D%5Btype%5D=all'} style={{ textDecoration: 'none' }}><h4 style={{ marginLeft: '15px' }}>1,360 Objects</h4></Link>
									</section>
								</Carousel.Item>

								<Carousel.Item>

									<section>
										<span className="icon major"><Link to={'/search?size=n_20_n&filters%5B0%5D%5Bfield%5D=type&filters%5B0%5D%5Bvalues%5D%5B0%5D=document&filters%5B0%5D%5Btype%5D=all'} style={{ textDecoration: 'none' }}><IoDocumentTextOutline size={50} /></Link></span>
										<Link to={'/search?size=n_20_n&filters%5B0%5D%5Bfield%5D=type&filters%5B0%5D%5Bvalues%5D%5B0%5D=document&filters%5B0%5D%5Btype%5D=all'} style={{ textDecoration: 'none' }}><h4 style={{ marginLeft: '15px' }}>484 Documents</h4></Link>
									</section>
								</Carousel.Item>

							</Carousel>

						</div>
						{windowWidth <= 500 ? <p>Please swipe to view additional items</p> : ''}
					</section>

					<h3>Projects:</h3>
					<section className="box special features">
						<div className="features-row">
						<section class="box special" >
								<span class="image featured"><Link to={'/projectbanraja'} style={{ textDecoration: 'none' }}><img src="https://d1i1jdw69xsqx0.cloudfront.net/images/projectbanrajafull.png" alt="Project Ban Raja" /></Link></span>
									<Link to={'/projectbanraja'} style={{ textDecoration: 'none' }}><h4 style={{marginTop: '-37px', marginBottom: '-20px'}}>Project Ban Raja</h4></Link>
								</section>	
						</div>
					</section>
					
					<h3>Digibits - Digital Exhibits:</h3>
					<section className="box special features">
						<div className="features-row">
							<section>
								<a href={'https://www.archivenepal.org/1923treaty'} style={{ textDecoration: 'none' }}><img src="https://images.eap.bl.uk/EAP838/EAP838_1_4_1/17.jp2/full/!1400,700/0/default.jpg" className='icon major' /></a>
								<a href={'https://www.archivenepal.org/1923treaty'} style={{ textDecoration: 'none' }}><h4>1923 Nepal - UK Treaty of friendship</h4></a>
							</section>

							<section>
								<a href={'https://www.archivenepal.org/unmembership'} style={{ textDecoration: 'none' }}><img src="https://upload.wikimedia.org/wikipedia/commons/2/2f/Small_Flag_of_the_United_Nations_ZP.svg" className='icon major' /></a>
								<a href={'https://www.archivenepal.org/unmembership'} style={{ textDecoration: 'none' }}><h4>Timeline of the U.N. membership of Nepal</h4></a>
							</section>
					
							<section>
								<a href={'https://www.archivenepal.org/womenofnepal'} style={{ textDecoration: 'none' }}><img src="https://d1i1jdw69xsqx0.cloudfront.net/images/won.jpg" className='icon major' /></a>
								<a href={'https://www.archivenepal.org/womenofnepal'} style={{ textDecoration: 'none' }}><h4>Influential women of Nepal over the years</h4></a>
							</section>

							<section>
								<a href={'https://www.archivenepal.org/constitutions'} style={{ textDecoration: 'none' }}><img src="https://d1i1jdw69xsqx0.cloudfront.net/images/constitution.png" className='icon major' /></a>
								<a href={'https://www.archivenepal.org/constitutions'} style={{ textDecoration: 'none' }}><h4>Timeline of the constitutions of Nepal</h4></a>
							</section>
						</div>
					</section>


					<h3>Browse by Digital Galleries:</h3>	<Link to={'/digitalCollections'} style={{ textDecoration: 'none' }}><h4 style={{ marginLeft: '25px' }}>View all</h4></Link>
					<div class="row">
						{/*<div class="col-6 col-12-narrower">
							<section class="box special">
								<span class="image featured"><Link to = {'/facesofnepal'} style={{textDecoration: 'none'}}><img src="https://d1i1jdw69xsqx0.cloudfront.net/images/thumbnails/facesofnepal1.png" alt="Faces of Nepal" /></Link></span>
								<h3>Faces of Nepal</h3>
									<Link to = {'/facesofnepal'} style={{textDecoration: 'none'}}><h4>Learn More</h4></Link>								
							</section>
				</div>*/}<div class="col-12 col-12-narrower">
							<Carousel cols={3} rows={1} gap={10} showDots>
									<Carousel.Item>
										<section class="box special" >
										<span class="image featured"><Link to={'/ganeshphotolab'} style={{ textDecoration: 'none' }}><img src="https://d1i1jdw69xsqx0.cloudfront.net/images/thumbnails/ganeshphotolab.png" alt="Ganesh Photo Lab" /></Link></span>
											<Link to={'/ganeshphotolab'} style={{ textDecoration: 'none' }}><h4 style={{marginTop: '-27px'}}>Ganesh Photo Lab: Portraits from the Past</h4></Link>
										</section>
									</Carousel.Item>
								<Carousel.Item>
									<section class="box special" >
										<span class="image featured"><Link to={'/dirghamanganeshman'} style={{ textDecoration: 'none' }}><img src="https://d1i1jdw69xsqx0.cloudfront.net/images/thumbnails/dirghamanganeshman.png" alt="Dirghaman and Ganeshman Collection" /></Link></span>
										<Link to={'/dirghamanganeshman'} style={{ textDecoration: 'none' }}><h4 style={{ marginTop: '-23px' }}>Dirghaman & Ganeshman Collection</h4></Link>
									</section>
								</Carousel.Item>
								<Carousel.Item>
									<section class="box special" >
										<span class="image featured"><Link to={'/aerialviews'} style={{ textDecoration: 'none' }}><img src="https://d1i1jdw69xsqx0.cloudfront.net/images/thumbnails/pashupatiDG.jpg" alt="Aerial Views of Nepal" /></Link></span>
										<Link to={'/aerialviews'} style={{ textDecoration: 'none' }}><h4>Aerial Views of Nepal</h4></Link>
									</section>
								</Carousel.Item>
								<Carousel.Item>
									<section class="box special" >
										<span class="image featured"><Link to={'/electionsinnepal'} style={{ textDecoration: 'none' }}><img src="https://d1i1jdw69xsqx0.cloudfront.net/images/thumbnails/electionsinnepal.png" alt="Elections in Nepal" /></Link></span>
										<Link to={'/electionsinnepal'} style={{ textDecoration: 'none' }}><h4>Elections in Nepal</h4></Link>
									</section>
								</Carousel.Item><Carousel.Item>
									<section class="box special" >
										<span class="image featured"><Link to={'/electionmanifestos2022'} style={{ textDecoration: 'none' }}><img src="https://d1i1jdw69xsqx0.cloudfront.net/images/thumbnails/electionmanifestos.png" alt="2022 (2079 B.S.) Election Manifestos" /></Link></span>

										<Link to={'/electionmanifestos2022'} style={{ textDecoration: 'none' }}><h4 style={{ marginTop: '-23px' }}>2022 (2079 B.S.) Election Manifestos</h4></Link>
									</section>
								</Carousel.Item><Carousel.Item>
									<section class="box special" >
										<span class="image featured"><Link to={'/indrajatra'} style={{ textDecoration: 'none' }}><img src="https://d1i1jdw69xsqx0.cloudfront.net/images/thumbnails/indrajatragallery.png" alt="Indra Jatra" /></Link></span>
										<Link to={'/indrajatra'} style={{ textDecoration: 'none' }}><h4>Indra Jatra</h4></Link>
									</section>
								</Carousel.Item><Carousel.Item>
									<section class="box special" >
										<span class="image featured"><Link to={'/facesofnepal'} style={{ textDecoration: 'none' }}><img src="https://d1i1jdw69xsqx0.cloudfront.net/images/thumbnails/facesofnepal.png" alt="Faces of Nepal" /></Link></span>
										<Link to={'/facesofnepal'} style={{ textDecoration: 'none' }}><h4>Faces of Nepal</h4></Link>
									</section>
								</Carousel.Item><Carousel.Item>
									<section class="box special" >
										<span class="image featured"><Link to={'/treatiesCollection'} style={{ textDecoration: 'none' }}><img src="/thumbnails/1923Treaty.png" alt="Historic Treaties and Documents" /></Link></span>
										<Link to={'/treatiesCollection'} style={{ textDecoration: 'none' }}><h4 style={{ marginTop: '-23px' }}>Historic Treaties and Documents</h4></Link>
									</section>
								</Carousel.Item><Carousel.Item>
									<section class="box special" >
										<span class="image featured"><Link to={'/BPBookCollection'} style={{ textDecoration: 'none' }}><img src="/thumbnails/BPBooks.png" alt="Books by B.P. Koirala" /></Link></span>
										<Link to={'/BPBookCollection'} style={{ textDecoration: 'none' }}><h4>Books by B.P. Koirala</h4></Link>
									</section>
								</Carousel.Item>
								<Carousel.Item>
									<section class="box special" >
										<span class="image featured"><Link to={'/objectCollection'} style={{ textDecoration: 'none' }}><img src="https://collectionapi.metmuseum.org/api/collection/v1/iiif/78187/1299669/main-image" alt="Nepali Sculptures" /></Link></span>
										<Link to={'/objectCollection'} style={{ textDecoration: 'none' }}><h4>Nepali Sculptures</h4></Link>
									</section>
								</Carousel.Item>
							</Carousel>
						</div>
					</div>

					<h3>Browse by the collection:</h3>
					<section className="box special features">
						<div className="features-row">
						<Carousel cols={4} rows={1} gap={20} showDots>
						<Carousel.Item>
						<section>
										<a href={'https://www.archivenepal.org/tenttomglenn'} style={{ textDecoration: 'none' }}><img src="https://d1i1jdw69xsqx0.cloudfront.net/images/tgc.PNG" className='icon major' /></a>
										<a href={'https://www.archivenepal.org/tenttomglenn'} style={{ textDecoration: 'none' }}><h4 style={{ marginLeft: '20px' }}>Tenttom Glenn Collection</h4></a>
									</section>
						</Carousel.Item>	
						
						<Carousel.Item>
						<section>
										<a href={'https://www.archivenepal.org/sushilasingh'} style={{ textDecoration: 'none' }}><img src="https://d1i1jdw69xsqx0.cloudfront.net/images/ssc2.png" className='icon major' /></a>
										<a href={'https://www.archivenepal.org/sushilasingh'} style={{ textDecoration: 'none' }}><h4 style={{ marginLeft: '20px' }}>Sushila Singh Collection</h4></a>
									</section>
						</Carousel.Item>	
						<Carousel.Item>
						<section>
										<a href={'https://www.archivenepal.org/johan_reinhard'} style={{ textDecoration: 'none' }}><img src="https://d1i1jdw69xsqx0.cloudfront.net/images/jrc.PNG" className='icon major' /></a>
										<a href={'https://www.archivenepal.org/johan_reinhard'} style={{ textDecoration: 'none' }}><h4 style={{ marginLeft: '20px' }}>Johan Reinhard Collection</h4></a>
									</section>
						</Carousel.Item>	
						<Carousel.Item>
						<section>
								<Link to={'/NepalPeaceCorps'} style={{ textDecoration: 'none' }}><img src="https://d1i1jdw69xsqx0.cloudfront.net/images/npc.PNG" className='icon major' /></Link>
								<Link to={'/NepalPeaceCorps'} style={{ textDecoration: 'none' }}><h4>Nepal Peace Corps</h4></Link>
							</section>
						</Carousel.Item>	
						<Carousel.Item>
						<section>
								<Link to={'/mpp'} style={{ textDecoration: 'none' }}><img src="https://d1i1jdw69xsqx0.cloudfront.net/images/mpp.png" className='icon major' /></Link>
								<Link to={'/mpp'} style={{ textDecoration: 'none' }}><h4>Madan Puraskar Pustakalaya</h4></Link>
							</section>
						</Carousel.Item>	
						<Carousel.Item>
						<section>
								<a href={'https://www.archivenepal.org/digitalhimalaya'} style={{ textDecoration: 'none' }}><img src="https://d1i1jdw69xsqx0.cloudfront.net/images/dhbg.png" className='icon major' /></a>
								<a href={'https://www.archivenepal.org/digitalhimalaya'} style={{ textDecoration: 'none' }}><h4>Digital Himalaya</h4></a>
							</section>
						</Carousel.Item>	
						<Carousel.Item>
						<section>
								<Link to={'/nepalitimes'} style={{ textDecoration: 'none' }}><img src="https://d1i1jdw69xsqx0.cloudfront.net/images/nepalitimesicon.PNG" className='icon major' /></Link>
								<Link to={'/nepalitimes'} style={{ textDecoration: 'none' }}><h4>Nepali Times</h4></Link>
							</section>
							</Carousel.Item>	
								
									</Carousel>
						
							
							
						
						</div>
					</section>

					
					<h3>News:</h3>
						<div class="row">
							<div class="col-12 col-12-narrower" style={{marginBottom: '15px'}}>
								<Carousel cols={3} rows={1} gap={10}>
								<Carousel.Item>
										<section class="box special" >
										<span class="image featured"><Link to={'/pressrelease_june_2024'} style={{ textDecoration: 'none' }} target='_blank'><img src="https://d1i1jdw69xsqx0.cloudfront.net/images/ANDH.PNG" alt="Digital Himalaya has joined the Archive Nepal family." /></Link></span>
											<Link to={'/pressrelease_june_2024'} style={{ textDecoration: 'none' }} target='_blank'><h4>Digital Himalaya has joined the Archive Nepal family</h4></Link>
										</section>
									</Carousel.Item>
									<Carousel.Item>
										<section class="box special" >
										<span class="image featured"><Link to={'/pressrelease'} style={{ textDecoration: 'none' }} target='_blank'><img src="https://d1i1jdw69xsqx0.cloudfront.net/images/Cause_Honoree_Archive+Nepal.jpeg" alt="2023 Cause Award Honoree for Elastic Excellence Award" /></Link></span>
											<Link to={'/pressrelease'} style={{ textDecoration: 'none' }} target='_blank'><h4>Archive Nepal gains international recognition</h4></Link>
										</section>
									</Carousel.Item>
									
								</Carousel>
							</div>
						</div>
				</section>
			</div>
		)
	}
}
